import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Stack,
  SvgIcon,
  Typography,
} from "@mui/material";
import KjosChip from "components/KjosChip";
import KjosList2 from "components/KjosList2";
import KjosSelect2 from "components/KjosSelect2";
import KjosSvgIcon from "components/KjosSvgIcon";
import ArrowRightIcon from "@heroicons/react/24/solid/ArrowRightIcon";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import ArrowDownOnSquareStackIcon from "@heroicons/react/24/solid/ArrowDownOnSquareStackIcon";
import XMarkIcon from "@heroicons/react/24/solid/XMarkIcon";
import { useState } from "react";
import KjosInput from "components/KjosInput";
import KjosSelect from "components/KjosSelect";
import KjosActionButton from "components/KjosActionButton";
import KjosDialog from "components/KjosDialog";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { useDispatch, useSelector } from "react-redux";
import { setOpen } from "store/AuthenticationReducer";
import {
  CLASSE_SIGNALEMENT_GET_URL,
  ADMIN_HABILETE_URL,
  CLASSE_SIGNALEMENT_CREATE_URL,
  ADMIN_CIRCUIT_TRAITEMENT_URL,
} from "config/RoutesUrl";
import useHttp from "hooks/useHttp";
import { useEffect } from "react";
import useStateWithCallback from "hooks/useStateWithCallBack";
import DialogBody from "components/DialogBody";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";
import { BorderTopRounded } from "@mui/icons-material";
import KjosSnacBar from "components/KjosSnackBar";
import { SwapVerticalCircleOutlined } from "@mui/icons-material";
import { DELIVERY_COLOR, FANCY_GREEN, FANCY_ORANGE, NEW_COLOR, OPEN_COLOR, PENDDING_COLOR, WITHOUT_CONTINUATION_COLOR } from "config/color";
var i = 0
const CircuitTraitement = (props) => {
  // Http
  const dispatch = useDispatch();
  const { getData, postData, putData, deleteData, deleteData2, getOneData } = useHttp(); // get data from server
  const { postResult, getResult, deleteResult, editResult } = useSelector(
    (state) => state.authentication
  ); // get result state
  const { itemsSelected } = useSelector((state) => state.table); // selected items from table

  // const circuitGetData = [
  //   {
  //     id: 25,
  //     classe: "Juridique",
  //     habillete: [["Operateur"], ["Manager"], ["Autorité Competente"]],
  //   },
  //   {
  //     id: 235,
  //     classe: "Sécuritaire",
  //     habillete: [["Operateurs"], ["Managers"], ["Juridique", "Superviseurs"]],
  //   },
  //   {
  //     id: 245,
  //     classe: "Technique",
  //     habillete: [["Operateurs"], ["Superviseurs", "Juridique", "Manager"]],
  //   },
  // ];

  // const [circuitPostData, setCircuitPostData] = useState([]);
  const [circuitPostData, setCircuitPostData] = useStateWithCallback([]);
  // const [habilletesPostData, setHabilletesPostData] = useState({});
  const [habilletesPostData, setHabilletesPostData] = useStateWithCallback({});
  const habilletesGetData = [
    {
      id: 25,
      value: "Opérateur",
      label: "Opérateur",
      color: "secondary",
    },
    {
      id: 27,
      value: "Superviseurs",
      label: "Superviseurs",
      color: "success",
    },
    {
      id: 28,
      value: "Juridique",
      label: "Juridique",
      color: "primary",
    },
    {
      id: 29,
      value: "Manager",
      label: "Manager",
      color: "info",
    },
  ];

  const [habilletes, setHabilletes] = useState([]);
  const [circuitGetData, setCircuitGetData] = useState([]);
  var habilletesLabel;
  const [onInputSlectChange, setOnInputSlectChange] = useState({});

  const chipColor = [OPEN_COLOR, NEW_COLOR, PENDDING_COLOR, DELIVERY_COLOR, WITHOUT_CONTINUATION_COLOR];

  const [edit, setEdit] = useState(false);
  const [remove, setRemove] = useState(false);
  const [save, setSave] = useState(false);
  const [cancel, setCancel] = useState(false);
  const [circuitId, setCircuitId] = useState(0);

  const handleEdit = (index, classeSignalementId) => {
    setCircuitPostData({ id: classeSignalementId, habiletes: null }, (prevValue, newValue) => {
    });

    setEdit(true);
    setCircuitId(index);
    setOnInputSlectChange({});
    setHabilletesPostData({});
    // setCircuitPostData({});
  };

  const hanldeRemove = async (index, classeSignalementId) => {
    setCircuitPostData(
      { id: classeSignalementId, habiletes: null },
      async (prevValue, newValue) => {
        // const resp = await putData(
        //   circuitPostData,
        //   CLASSE_SIGNALEMENT_CREATE_URL + "/" + circuitPostData?.id
        // );
        const resp = await deleteData2(CLASSE_SIGNALEMENT_CREATE_URL + "/" + circuitPostData?.id);
        // 
        setEdit(false);
        setSnackbar({
          open: true,
          message: resp?.error ? "Ereur non mise à jour" : "Retirer avec succès",
          severity: resp?.error ? "error" : "success",
          autoHideDuration: 5000,
        });
      }
    );
    //add
  };

  const handleSave = async () => {
    const resp = await putData(
      circuitPostData,
      CLASSE_SIGNALEMENT_CREATE_URL + "/" + circuitPostData?.id
    ); //add
    setEdit(false);
    setSnackbar({
      open: true,
      message: resp?.error ? "Ereur non mise à jour" : resp?.message,
      severity: resp?.error ? "error" : "success",
      autoHideDuration: 5000,
    });

    setOnInputSlectChange({});
    setHabilletesPostData({});
    setCircuitPostData({});
    setCircuitPostData({ ...circuitPostData }, (prevValue, newValue) => {
      newValue = [];
    });
  };

  const handleCancel = () => {
    setEdit(false);
    setOnInputSlectChange({});
    setHabilletesPostData({});
    setCircuitPostData({});
    setCircuitPostData({ ...circuitPostData }, (prevValue, newValue) => {
      newValue = [];
    });
  };

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
    autoHideDuration: 5000,
  });

  const handleOncloseSanackbar = () => {
    setSnackbar({
      ...snackbar,
      open: false,
    });
  };

  const handleCircuitChange = (event, index, idClasseAndIndex, libelle, classId) => {
    var {
      target: { value, id },
    } = event;

    // Get Habiletes id
    var valueArray = JSON.stringify(value)?.replace(/[\[\]"]/g, "");
    var habileteIdValue = valueArray?.match(/\d+(?=#)/g)?.map(Number);
    // habileteIdValue = JSON.stringify(habileteIdValue).replace(/[\[\]"]/g, "");
    setOnInputSlectChange((prev) => ({
      ...prev,
      [idClasseAndIndex]: typeof value === "string" ? value.split(",") : value,
    }));

    setHabilletesPostData(
      {
        ...habilletesPostData,
        [index]: typeof value === "string" ? value.split(",") : habileteIdValue,
      },
      (prevValue, newValue) => {
        setCircuitPostData(
          {
            ...circuitPostData,
            id: classId,
            libelle: libelle,
            habiletes: newValue,
          },
          (prevValue1, newValue1) => {
            // 
          }
        );
      }
    );
  };

  // Effet
  useEffect(() => {
    async function fetchData() {
      const circ = await getData(ADMIN_CIRCUIT_TRAITEMENT_URL);
      const habil = (await getData(ADMIN_HABILETE_URL))?.data?.map((item) => ({
        id: item.id,
        value: item.libelle,
        label: item.libelle,
      }));
      
      setCircuitGetData(circ?.data?.data);
      setHabilletes(habil);
    }
    fetchData(); // get data when component is mounted
  }, [getData, postResult, deleteResult, editResult, circuitPostData]);

  useEffect(() => {}, [itemsSelected]);

  return (
    <Box component={"main"} sx={{ flexGrow: 1, p: "1rem" }}>
      <Container maxWidth={"xl"}>
        <Typography variant="h6" mb={2}>
          Circuit de traitement
        </Typography>

        <Stack
          direction="column"
          justifyContent="space-between"
          sx={{ borderRadius: 2, boxShadow: 3 }}
          spacing={2}
        >
          <Grid
            container
            space={2}
            sx={{
              textTransform: "uppercase",
              backgroundColor: "#F8F9FA",
              p: 2,
              fontSize: 14,
              borderRadius: 2,
            }}
          >
            <Grid item xs={1}>
              <Typography variant="inherit">N° </Typography>
            </Grid>
            <Grid item xs={2}>
              {" "}
              <Typography variant="inherit" sx={{ wordBreak: "break-all" }}>
                Classe
              </Typography>
            </Grid>
            <Grid item xs={7}>
              {" "}
              <Typography variant="inherit" sx={{ wordBreak: "break-all", flexGrow: 1 }}>
                Circuits
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="inherit">Action </Typography>
            </Grid>
          </Grid>
          <Grid container>
            {circuitGetData?.map((classeSignalement, index1) => (
              <Grid
                container
                p={2}
                key={index1}
                sx={{ "&:hover": { backgroundColor: "#F8F9FA" } }}
                alignContent={"center"}
                alignItems={"center"}
              >
                <Grid item xs={12} md={1}>
                  <Typography variant="inherit">{index1 + 1} </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography variant="inherit" sx={{ wordBreak: "break-all" }}>
                    {classeSignalement?.libelle}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={7} alignItems={"center"} px={3}>
                  <Stack direction={"row"} spacing={1} alignItems={"center"}>
                    <Grid container spacing={1} alignItems={"center"}>
                      {edit && index1 === circuitId
                        ? [1, 2, 3, 4].map((item, i) => (
                            <Grid item xs={12} key={i}>
                              <Stack
                                direction={{ xs: "column", lg: "row" }}
                                spacing={2}
                                alignItems={"center"}
                              >
                                <Typography variant="inherit"> Etape {i + 1} </Typography>
                                <KjosSelect
                                width="300px"
                                height="40px"
                                  name={"classe_"}
                                  dataSelect="dataselect"
                                  options={habilletes}
                                  defaultValue=""
                                  select={true}
                                  onChange={(e) =>
                                    handleCircuitChange(
                                      e,
                                      i,
                                      classeSignalement?.id + i,
                                      classeSignalement?.libelle,
                                      classeSignalement?.id
                                    )
                                  }
                                  value={onInputSlectChange[classeSignalement?.id + i] ?? []}
                                  key={classeSignalement?.id + i}
                                />
                              </Stack>
                            </Grid>
                          ))
                        : classeSignalement?.habiletes?.map((habilleteArray, index2) => (
                            <Stack
                              direction={"row"}
                              alignItems={"center"}
                              key={index2}
                              spacing={1}
                              justifyContent={"center"}
                              flexGrow={1}
                            >
                              <Stack
                                direction={"row"}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                                flexGrow={1}
                              >
                                <Grid item>
                                  {habilleteArray?.map((habillete, index3) => {
                                    habilletes?.map((hab, index3) => {
                                      if (habillete === hab.id) {
                                        habilletesLabel = hab.label;
                                        return false;
                                      }
                                    });
                                    return (
                                      <Grid item key={index3}>
                                        <KjosChip
                                          label={habilletesLabel}
                                          variant="filled"
                                          color={chipColor[i>5?i=0:i++]}
                                        />
                                      </Grid>
                                    );
                                  })}
                                </Grid>

                                <Stack sx={{ mx: "auto" }}>
                                  {index2 === classeSignalement?.habiletes.length - 1 ? (
                                    ""
                                  ) : (
                                    <KjosSvgIcon icon={<ArrowRightIcon />} />
                                  )}
                                </Stack>
                              </Stack>
                            </Stack>
                          ))}
                    </Grid>
                  </Stack>
                </Grid>
                <Grid item xs={2}>
                  {edit && index1 === circuitId ? (
                    <Stack direction="row" alignItems="space-between">
                      <IconButton
                        sx={{ color: FANCY_GREEN }}
                        onClick={(e) => {
                          handleSave(index1);
                        }}
                      >
                        <KjosSvgIcon icon={<ArrowDownOnSquareStackIcon />} />
                      </IconButton>
                      <IconButton
                      sx={{color: FANCY_ORANGE}}
                        // color="error"
                        onClick={(e) => {
                          handleCancel(index1);
                        }}
                      >
                        <KjosSvgIcon icon={<XMarkIcon />} />
                      </IconButton>
                    </Stack>
                  ) : (
                    <Stack direction="row" alignItems="space-between">
                      <IconButton
                        sx={{ color: FANCY_GREEN }}
                        onClick={(e) => {
                          handleEdit(index1, classeSignalement?.id);
                        }}
                        disabled={edit}
                      >
                        <KjosSvgIcon icon={<PencilIcon />} />
                      </IconButton>
                      <IconButton
                      sx={{color: FANCY_ORANGE}}
                      onClick={(e) => {
                          hanldeRemove(index1, classeSignalement?.id);
                        }}
                        disabled={edit}
                      >
                        <KjosSvgIcon icon={<TrashIcon />} />
                      </IconButton>
                    </Stack>
                  )}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </Container>
      <KjosSnacBar
        open={snackbar?.open}
        message={snackbar?.message}
        severiry={snackbar?.severity}
        onClose={handleOncloseSanackbar}
        autoHideDuration={snackbar?.autoHideDuration}
      />
    </Box>
  );
};

export default CircuitTraitement;
