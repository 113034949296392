import axios from "axios";
import { BASE_URL, BASE_URL_API, CSRF_COOKIES, LOGIN_URL } from "config/RoutesUrl";
import { setAuth, setUser, seterror, setloading } from "store/AuthenticationReducer";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useLocalSorage from "./useLocalSorage";
import { DASHBOARD_PATH } from "config/PathUrl";
import { useState } from "react";
import { useEffect } from "react";

function useAuthenticate(tokenUrl = BASE_URL + CSRF_COOKIES, loginUrl = BASE_URL_API + LOGIN_URL) {
  const router = useNavigate();
  const [getLocalStorage, removeLocalStorage, setLocalStorage] = useLocalSorage();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(null);
  const [datas, setDatas] = useState(null)

  const handleFormSubmit = useCallback(
    async (param, action = DASHBOARD_PATH) => {
      param.preventDefault(); // prevent default  
      const formData = new FormData(param.target); // get form data
      const data = {
        email: formData.get("email"),
        password: formData.get("password"),
        role: "admin",
      }; // get form data
      axios.defaults.withCredentials = false;
      try {
        await axios.get(tokenUrl); // ping the route
        const request = await axios.post(loginUrl, data); // get user
        const response = request;

        if (response?.data?.error) {
          setError(response?.data?.error); // save error
          setDatas(response?.data?.data ?? response?.data); // save error data
        } else {
          setLocalStorage("admin", response?.data?.data);
          setDatas(response?.data?.data); // save user in state
          
          router(action);
        }
        setLoading(false); // stop loader
      } catch (error) {
        setError({ serverError: true, error: error });
      }
    },
    [loginUrl, router, setLocalStorage, tokenUrl]
  );

  useEffect(() => {
  }, [datas, error, loading]);

  return {handleFormSubmit, error, loading, datas};
}

export default useAuthenticate;
