import { useState, useEffect } from "react";
import { Box, Container, Unstable_Grid2 as Grid, Stack } from "@mui/material";
import useDashboard from "hooks/useDashboard";
import numberFormater from "controller/numberFormater";
import useHttp from "hooks/useHttp";
import { ADMIN_DASHBOARD_PLAINTE_URL, ADMIN_INDEX_TRAITEMENT, ADMIN_PLAINTE_URL, ADMIN_SIGNALLEMENT_URL, USER_URL } from "config/RoutesUrl";
import useStatut from "hooks/useStatut";
import getTimeElapsedString from "controller/getTimeElapsedString";
import useRole from "hooks/useRole";
import useUser from "hooks/useUser";
import KjosAuthorized from "components/KjosAuthorized";
import KjosChart from "components/KjosChart";
import KjosPieChart from "components/KjosPieChart";
import KjosHeader from "components/KjosHeader";
import User from "./signalements/overview/User";
import Signalements from "./signalements/overview/Signalements";
import PlaintesDashboard from "./signalements/overview/PlaintesDashboard";
import ClientMobile from "./signalements/overview/ClientMobile";
import LastUser from "./signalements/overview/LastUser";
import LastSignalement from "./signalements/overview/LastSignalement";
import Layout from "layouts/layout";
import getDefaultImage from "controller/getDefaultImage";
import KjosTitle from "components/KjosTitle";
import KjosSyncButton from "components/KjosSyncButton";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "store/SignalementReducer";
import { FANCY_BLUE, FANCY_GREEN, FANCY_ORANGE } from "config/color";
import { Home } from "@mui/icons-material";
import { PLAINTES_LIST_PATH, SIGNALEMENTS_URL } from "config/PathUrl";
import KjosInput2 from "components/KjosInput2";
const month = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const monthNames = ["jan", "Fev", "Mar", "Avr", "Mai", "Jui", "Juil", "Août", "Sept", "Oct", "Nov", "Dec"];

const Dashboard = () => {
  // Hook de gestion du tableau de bord
  const { getData } = useHttp();
  const { getDashoboard } = useDashboard();
  const [dashboard, setDashboard] = useState({});
  const [plaintes, setPlaintes] = useState([]);
  const [plaintesUsers, setPlaintesUsers] = useState([]);
  const [lastUsers, setLastusers] = useState([]);
  const [lastSignalements, setLastsignalements] = useState([]);
  const { label, color } = useStatut();
  const isSuperAdmin = useRole("admin", "superadmin");
  const { getUser } = useUser();
  const [chartSeries, setChartSeries] = useState([]);
  const [totalPlaintes, setTotalPlaintes] = useState(0);
  const [chartSeriesPlaintes, setChartSeriesPlaintes] = useState([]);
  const [PieChartSeries, setPieChartSeries] = useState([]);
  const [PieChartSeriesPlaintes, setPieChartSeriesPlaintes] = useState([]);
  const [groupe_by_date_start, setGroupe_by_date_start] = useState("");
  const [groupe_by_date_end, setGroupe_by_date_end] = useState("");
  const [date_fin_min, setdate_fin_min] = useState("");
  const [disabledEndDate, setdisabledEndDate] = useState(true);
  const [users, setUsers] = useState([]);
  const [signalements, setSignalements] = useState([]);
  const { update } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();

  const handleDateDebut = async (e) => {
    setGroupe_by_date_start(e.target.value);
    setdate_fin_min(e.target.value);
  };

  const handleDateFin = async (e) => {
    setGroupe_by_date_end(e.target.value);
  };

  useEffect(() => {
    const users = getData(USER_URL).then((resp) => {
    });
    getDashoboard("superadmin").then((resp) => {
      const evolution = resp?.data?.evolutionSignalements;
      if (Array.isArray(resp?.data?.evolutionSignalements)) {
        const sign = monthNames.map((name, index) => {
          const item = evolution.find((item) => item.mois === index + 1);
          return {
            Signalement: item ? item.total : 0,
            name: name,
          };
        });

        setChartSeries(sign);
      }

      const pie = [
        {
          name: "Nouveau",
          value: resp?.data?.rate_array?.new ?? 0,
        },
        {
          name: "En cours",
          value: resp?.data?.rate_array?.pendding ?? 0,
        },
        {
          name: "Ouvert",
          value: resp?.data?.rate_array?.open ?? 0,
        },
        {
          name: "Classé sans suite",
          value: resp?.data?.rate_array?.without_continuation ?? 0,
        },
        {
          name: "Terminé",
          value: resp?.data?.rate_array?.delivery ?? 0,
        },
      ];

      setPieChartSeries(pie);

      setDashboard(resp);
    });

    if (isSuperAdmin) {
      getData(ADMIN_SIGNALLEMENT_URL + "?page=" + 1 + "&per_page=" + 5 + "&order_by=created_at&order=desc").then((resp) => {
        const sign = resp?.data?.data?.map((item) => ({
          id: item.id,
          date: "Création il y'a " + getTimeElapsedString(new Date(item?.date)),
          ticket: item.code,
          user: item?.user ? item.user?.nom + " " + item.user?.prenom : "Anonyme",
          statut: label(item.statut) ?? "",
        }));
        setLastsignalements(sign);
      });

      // Plaintes
      getData(ADMIN_PLAINTE_URL + "?page=" + 1 + "&per_page=" + 5 + "&order_by=created_at&order=desc").then((resp) => {
        const plt = resp?.data?.map((item) => ({
          id: item.id,
          date: "Création il y'a " + getTimeElapsedString(new Date(item?.created_at)),
          ticket: item.code,
          user: item?.user ? item.user?.nom + " " + item.user?.prenom : "Anonyme",
          statut: label(item.status === null ? "new" : item.status) ?? "",
        }));
        setPlaintes(plt);
      });
    } else {
      getData(ADMIN_INDEX_TRAITEMENT + "?page=" + 1 + "&per_page=" + 5 + "&habilete_id=" + getUser("admin")?.user?.habilete_id).then((resp) => {
        const sign = resp?.data?.data?.map((item) => ({
          id: item.id,
          date: "Création il y'a " + getTimeElapsedString(new Date(item?.date)),
          ticket: item.code,
          user: item?.user ? item.user?.nom + " " + item.user?.prenom : "Anonyme",
          statut: label(item.statut) ?? "",
        }));
        setLastsignalements(sign);
      });

      // Plaintes
      getData(ADMIN_PLAINTE_URL + "?page=" + 1 + "&per_page=" + 5 + "&order_by=created_at&order=desc&admin_id=" + getUser("admin")?.user?.id).then((resp) => {
        const plt = resp?.data?.map((item) => ({
          id: item.id,
          date: "Création il y'a " + getTimeElapsedString(new Date(item?.created_at)),
          ticket: item.code,
          user: item?.user ? item.user?.nom + " " + item.user?.prenom : "Anonyme",
          statut: label(item.status === null ? "new" : item.status) ?? "",
        }));

        setPlaintes(plt);
      });
    }

    getData(USER_URL + "?page=" + 1 + "&per_page=" + 5 + "&order_by=created_at&order=desc").then((resp) => {
      if (!Array.isArray(resp?.data)) return;
      const usr = resp?.data?.map((item) => ({
        id: item.id,
        nom: item?.nom || item?.prenom ? item?.nom + " " + item?.prenom : "Anonyme",
        photo: getDefaultImage(item.photo),
        created_at: getTimeElapsedString(new Date(item.created_at ?? 0)),
      }));

      setLastusers(usr);
      dispatch(setLoading(false)); // setLoading
    });

    // Get plainte
    getData(ADMIN_DASHBOARD_PLAINTE_URL).then((resp) => {
      if (Array.isArray(resp?.data?.evolutionPlaintes)) {
        const plt = monthNames.map((name, index) => {
          const item = resp?.data?.evolutionPlaintes.find((item) => item.mois === index + 1);
          return {
            Plainte: item ? item.total : 0,
            name: name,
          };
        });

        setChartSeriesPlaintes(plt);
      }

      const pie = [
        {
          name: "Nouveau",
          value: resp?.data?.rate_array?.new ?? 0,
        },
        {
          name: "Classé sans suite",
          value: resp?.data?.rate_array?.without_continuation ?? 0,
        },
        {
          name: "Pris en charge",
          value: resp?.data?.rate_array?.take_charge ?? 0,
        },
      ];

      setTotalPlaintes(resp?.data?.totalPlaintes ?? 0);
      setPieChartSeriesPlaintes(pie);
    });
  }, [getDashoboard, getData, label, update]);

  return (
    <KjosAuthorized permission="show_dashboard">
      <KjosHeader>Tableau de Bord | SIGNAL - Gestion des signalements</KjosHeader>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <KjosTitle icon={<Home />} title="Tableau de bord" subtitle="Statistiques" iconBgColor={FANCY_BLUE} />
          <br />
          <KjosSyncButton />

          {/* <Typography variant="h6">Signalements non traités</Typography> */}
          {/* <Stack direction={{ xs: "column", md: "row" }} alignItems={"flex-end"} spacing={2}>

            <KjosInput2 type="date" id="groupe_by_date_start" label="Date début" name="groupe_by_date_start" width={"100%"} max={new Date().toISOString().split("T")[0]} value={groupe_by_date_start} onChange={handleDateDebut} />

            <KjosInput2 type="date" id="groupe_by_date_end" label="Date fin" name="groupe_by_date_end" width={"100%"} min={date_fin_min} max={new Date().toISOString().split("T")[0]} disabled={disabledEndDate} value={groupe_by_date_end} onChange={handleDateFin} />

          </Stack> */}

          <br />

          <Grid container spacing={3}>
            <Grid xs={12} sm={6} lg={3}>
              <User difference={12} positive sx={{ height: "100%", backgroundColor: "#f05170", color: "white" }} value={numberFormater(dashboard?.data?.user ?? 0)} />
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <Signalements difference={16} positive={false} sx={{ height: "100%", backgroundColor: FANCY_BLUE, color: "white" }} value={numberFormater(dashboard?.data?.signalements ?? 0).toString()} />
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              <PlaintesDashboard sx={{ height: "100%", backgroundColor: FANCY_GREEN, color: "white" }} value={numberFormater(totalPlaintes)} />
            </Grid>
            <Grid xs={12} sm={6} lg={3}>
              {/* <ClientMobile sx={{ height: "100%", backgroundColor: FANCY_ORANGE, color: "white" }} value={String(0)} /> */}
            </Grid>
            <Grid xs={12} lg={7}>
              <KjosChart data={chartSeries} sx={{ height: 400 }} title="Signalement par mois" dataKey="Signalement" />
            </Grid>
            <Grid xs={12} lg={5}>
              <KjosPieChart data={PieChartSeries} sx={{ height: 400 }} title="Taux de traitement des Signalements" />
            </Grid>

            {/* Plaintes */}
            <Grid xs={12} lg={7}>
              <KjosChart data={chartSeriesPlaintes} sx={{ height: 400 }} title="Plaintes par mois" dataKey="Plainte" />
            </Grid>
            <Grid xs={12} lg={5}>
              <KjosPieChart data={PieChartSeriesPlaintes} sx={{ height: 400 }} title="Taux de traitement des Plaintes" />
            </Grid>

            <Grid xs={12} lg={4}>
              <LastUser users={lastUsers} sx={{ height: "100%" }} />
            </Grid>
            <Grid xs={12} lg={8}>
              <LastSignalement signalements={lastSignalements} sx={{ height: "100%" }} title="Derniers signalements" seeMore={SIGNALEMENTS_URL} />
            </Grid>

            <Grid xs={12} lg={8}>
              <LastSignalement signalements={plaintes} sx={{ height: "100%" }} title="Dernières plaintes" seeMore={PLAINTES_LIST_PATH} />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </KjosAuthorized>
  );
};

export default Dashboard;
