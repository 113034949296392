
export default function formatDate(current_date){


if (current_date) {
  const date = new Date(current_date);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const fullDateOfBirth = `${year}-${month}-${day}`;
   return fullDateOfBirth
} else {
}
}