// anlc-admin/src/pages/auth/resetPassword.js
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { MAIN_BLUE } from "config/color";
import useAuthenticate from "hooks/useAuthenticate";
import KjosHeader from "components/KjosHeader";
import Layout from "guards/layout";
import { useLocation } from "react-router-dom";
import KjosAlert from "components/KjosAlert";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setAlert } from "store/SignalementReducer";
import { Link } from "react-router-dom";
import useHttp from "hooks/useHttp";
import { ADMIN_RESET_PASSWORD_URL } from "config/RoutesUrl";

const ResetPassword = () => {
  const { postData } = useHttp();
  const [emailInput, setEmailInput] = useState();
  const [helperText, setHelperText] = useState("");
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [successMsg, setSuccessMsg] = useState("");

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setErrorText("");
    setHelperText("");
    setSuccessMsg("");
    setLoading(true);
    const postRequest = await postData({ email: emailInput }, ADMIN_RESET_PASSWORD_URL);
    
    if (postRequest?.error) {
      setErrorText(postRequest?.message);
      setHelperText(postRequest?.message);
      setLoading(false);
      setSuccess(false);
      setSuccessMsg("");
    } else {
      setErrorText("");
      setHelperText("");
      setLoading(false);
      setSuccess(true);
      setSuccessMsg(postRequest?.message);
    }
  };

  return (
    <Layout>
      <KjosHeader>Reset Password | ANLC Gestion des signalements</KjosHeader>
      <Box
        sx={{
          backgroundColor: "background.paper",
          flex: "1 1 auto",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: "100px",
            width: "100%",
          }}
        >
          {success ? (
            <Stack direction={"column"} spacing={1} sx={{ mb: 3 }}>
              {success && <Typography variant="h6">{successMsg}</Typography>}
            </Stack>
          ) : (
            <div>
              <Typography variant="h6">Veuillez entrer votre adresse mail</Typography>
              <form>
                <Stack spacing={3}>
                  <TextField
                    error={errorText}
                    fullWidth
                    helperText={helperText}
                    label="Email Address"
                    type="email"
                    onChange={(e) => {
                      setErrorText("");
                      setHelperText("");
                      setEmailInput(e.target.value);
                    }}
                  />
                </Stack>
                <Typography sx={{ mt: 3 }} variant="body2">
                  {loading && "Envoie..."}
                </Typography>
                <Button
                  fullWidth
                  size="large"
                  sx={{
                    mt: 3,
                    backgroundColor: MAIN_BLUE,
                    color: "white",
                    ":hover": { backgroundColor: MAIN_BLUE },
                  }}
                  onClick={handleFormSubmit}
                >
                  Envoyer
                </Button>
              </form>
            </div>
          )}
        </Box>
      </Box>
    </Layout>
  );
};

export default ResetPassword;
