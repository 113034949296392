import { Backdrop, Box, Button, Chip, Container, Grid, Stack, SvgIcon, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHttp from "hooks/useHttp";
import { ADMIN_HABILETE_URL, ADMIN_MESSAGES_URL, ADMIN_SIGNALLEMENT_URL, ADMIN_TYPE_message_URL } from "config/RoutesUrl";
import KjosSttepper from "components/KjosStepper";
import KjosChip from "components/KjosChip";
import formatUserName from "controller/formatUserName";
import formatDate2 from "controller/formatDate2";

const MessageDetail = () => {
  const { getOneData, getData } = useHttp(); // get data from server
  const router = useLocation();
  const [message, setMessage] = useState([]); // open snackbar

  useEffect(() => {
    // Marque message as open
    async function fetchData() {
      const sign = await getOneData(router?.state?.id, ADMIN_MESSAGES_URL);

      setMessage(sign?.data);
    }
    fetchData(); // get data when component is mounted
  }, [router?.state?.id]);

  return (
    <Box component={"main"} sx={{ flexGrow: 1, p: "1rem", backgroundColor: "#F6F7FA", borderRadius: "10px" }}>
      <Container maxWidth={"xl"} sx={{ backgroundColor: "white", p: 5, borderRadius: 2, boxShadow: "0px 0px 5px grey" }}>
        <Grid container spacing={2}>
          <Stack
            spacing={2}
            sx={{
              p: 4,
              backgroundColor: "#F6F7FA",
              borderRadius: "10px",
              boxShadow: "0px 0px 5px grey",
              width: "100%",
            }}
            direction={"column"}
            justifyContent={"space-between"}
          >
            <Typography variant="h6">Détails</Typography>

            <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
              <Typography variant="body">Date de création</Typography>
              <Typography variant="body">{formatDate2(message?.created_at)}</Typography>
            </Stack>

            <Stack spacing={2} direction={"row"} justifyContent={"space-between"}>
              <Typography variant="body">Titre</Typography>
              <Typography variant="body">{message?.title}</Typography>
            </Stack>

            <Stack spacing={2} direction={"column"}>
              <Typography variant="body">Contenu</Typography>
              <Stack
                sx={{
                  p: 4,
                  height: "10rem",
                  overflow: "auto",
                  backgroundColor: "white",
                  borderRadius: "10px",
                }}
              >
                <Typography variant="body">
                  <div dangerouslySetInnerHTML={{ __html: message.text }} />
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Container>
    </Box>
  );
};

export default MessageDetail;
