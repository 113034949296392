import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { MAIN_BLUE } from "config/color";
import useAuthenticate from "hooks/useAuthenticate";
import KjosHeader from "components/KjosHeader";
import Layout from "guards/layout";
import { useLocation } from "react-router-dom";
import KjosAlert from "components/KjosAlert";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { setAlert } from "store/SignalementReducer";
import { Link } from "react-router-dom";
import { RESET_PASSWORD_PATH } from "config/PathUrl";
import ReCAPTCHA from "react-google-recaptcha";
import { REACT_APP_SITE_KEY } from "config/params";
import useHttp from "hooks/useHttp";
import useAlert from "hooks/useAlert";
import { SIGNIN_CONFIRM_RECAPTCHA_URL } from "config/RoutesUrl";

const Login = () => {
  const {alert } = useSelector((state) => state.signalement);
  const dispatch = useDispatch();
  const recaptcha = useRef();
  const { postData } = useHttp();
  const { errorAlert } = useAlert();
  
  useEffect(() => {
  return () => {
    dispatch(
      setAlert({
        open: false,
        message: "",
      }));
  };
  },[])

  const { handleFormSubmit, loading, error, datas } = useAuthenticate();
  const location = useLocation();

  const submitForm = async (e) => {
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      errorAlert("Vous devez confirmer que vous n'êtes pas un robot");
    } else {
      //check recaptcha
      const result = await postData({ recaptcha: captchaValue }, SIGNIN_CONFIRM_RECAPTCHA_URL);
      console.log("result", result.error);
      if (result.error) {
        errorAlert("Échec de la validation reCAPTCHA.");
      } else {
        handleFormSubmit(e);
      }
    }
  };

  return (
    <Layout>
      {
        location.state && location.state.password_changed && <KjosAlert label={alert.message} />
      }
      <KjosHeader>
        Login | SIGNAL Gestion des signalements
      </KjosHeader>
      <Box
        sx={{
          backgroundColor: "background.paper",
          flex: "1 1 auto",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: 550,
            px: 3,
            py: "100px",
            width: "100%",
          }}
        >
      <KjosAlert label={alert.message} />
      <div>
            <Stack spacing={1} sx={{ mb: 3 }}>
              <Typography variant="h6">Login</Typography>
            </Stack>

            <form onSubmit={submitForm}>
              <Stack spacing={3}>
                <TextField
                  error={error && datas?.email && error}
                  fullWidth
                  helperText={error && datas?.email}
                  label="Email Address"
                  name="email"
                  type="email"
                />
                <TextField
                  error={error && datas?.password && error}
                  fullWidth
                  helperText={error && datas?.password}
                  label="Mot de passe"
                  name="password"
                  type="password"
                />
              </Stack>
              {error && (
                <Typography color="error" sx={{ mt: 3 }} variant="body2">
                  {datas?.message}
                </Typography>
              )}
              <Button
                fullWidth
                size="large"
                sx={{
                  mt: 3,
                  backgroundColor: MAIN_BLUE,
                  color: "white",
                  ":hover": { backgroundColor: MAIN_BLUE },
                }}
                type="submit"
              >
                Continue
              </Button>
              <Typography variant="body2" sx={{mt: 1}} >
                Mot de passe oublié, <Link to={RESET_PASSWORD_PATH}> cliquez ici.</Link>
              </Typography>

              <ReCAPTCHA ref={recaptcha} sitekey={REACT_APP_SITE_KEY} />

            </form>
          </div>
        </Box>
      </Box>
    </Layout>
  );
};

export default Login;
