import { Box, Container, Stack, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import AdminAdd from "pages/administrateurs/AdminAdd";
import KjosAuthorized from "components/KjosAuthorized";
import KjosHeader from "components/KjosHeader";
import KjosTitle from "components/KjosTitle";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { FANCY_BLUE } from "config/color";

const AddAdmin = () => {
 
  return (
    <KjosAuthorized permission="create_admin">
      <KjosHeader>
        Compte Administrateur| SIGNAL
      </KjosHeader>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <Stack spacing={3}>
          <KjosTitle icon={<PlusIcon />} title="Administrateur" subtitle="Creation d'un administrateur" iconBgColor={FANCY_BLUE} />
          <br />
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <AdminAdd />
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    </KjosAuthorized>
  );
};


export default AddAdmin;
