import { useCallback } from "react";
import useHttp from "./useHttp";
import { ADMIN_MESSAGES_URL, ADMIN_USER_URL } from "config/RoutesUrl";
import formatDate from "controller/formatDate";
import { setCount } from "store/SignalementReducer";
import { useDispatch } from "react-redux";

function useMessage() {
  const { getData, postData,  } = useHttp(); // get data from server
  const dispatch = useDispatch();

  const createMessage = useCallback(
    async (data) => {
      const message = await postData(data, ADMIN_MESSAGES_URL);
      return message;
    },
    [postData]
  );

  const getMessage = useCallback(
    async (page = 1, per_page = 5, query = null) => {
      const message = await getData(ADMIN_MESSAGES_URL + "?page=" + page + "&per_page=" + per_page);
      dispatch(setCount(message?.total));

      if(message?.error) {
        return;
      }
      return message?.data?.map(msg => { 
        return {
          id: msg.id,
         date: formatDate(msg.created_at),
         title: msg.title,
         content: <div dangerouslySetInnerHTML={{ __html: msg.text?.replace(/<\/?p>/g, ' ')?.slice(0, 100) }} /> 
        }
      });
      return message;
    },
    [postData]
  );

  const searchMessage = useCallback(
    async (query) => {
      const message = await getData(ADMIN_MESSAGES_URL + "?" + query);
      return message;
    },
    [postData]
  );
  return { createMessage, getMessage, searchMessage};
}

export default useMessage;
