import { useState } from "react";
/* Ho to use  */
/* 
setBananas(bananas + 1, (prevValue, newValue) => {
        
    });
*/
const useStateWithCallback = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const setValueAndCallback = (newValue, callback) => {
        setValue(prevValue => {
            if (callback) {
                callback(prevValue, newValue);
            }
            return newValue;
        });
    };

    return [value, setValueAndCallback];
}

export default  useStateWithCallback;