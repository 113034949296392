import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import CogIcon from "@heroicons/react/24/solid/CogIcon";
import LockClosedIcon from "@heroicons/react/24/solid/LockClosedIcon";
import ShoppingBagIcon from "@heroicons/react/24/solid/ShoppingBagIcon";
import UserIcon from "@heroicons/react/24/solid/UserIcon";
import UserPlusIcon from "@heroicons/react/24/solid/UserPlusIcon";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import XCircleIcon from "@heroicons/react/24/solid/XCircleIcon";
import { SvgIcon } from "@mui/material";
import useRole from "./useRole";
import KjosImage from "components/KjosImage";
import {
  icon_admins,
  icon_dashboard,
  icon_settings,
  icon_signalements,
  icon_users,
  logo_guinee,
} from "config/image";
import { useTheme } from "@mui/system";
import usePermission from "./usePermission";
import { useEffect } from "react";
import { useState } from "react";
import { ADMINS_URL, ARTICLE_LIST_PATH, CMS_URL, DASHBOARD_PATH, MESSAGE_PATH, PLAINTES_LIST_PATH, RAPPORT_PATH, SIGNALEMENTS_URL, UTILISATEURS_URL, WORKFLOW_URL } from "config/PathUrl";
import WebIcon from '@mui/icons-material/Web';
import DescriptionIcon from '@mui/icons-material/Description';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import PersonIcon from '@mui/icons-material/Person';
import ReportIcon from '@mui/icons-material/Report';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Home, Message } from "@mui/icons-material";
import BellAlertIcon from "@heroicons/react/24/solid/BellAlertIcon";


function useNavLink() {
  const role = useRole("admin", "superadmin");
  const { hasPermission, getAllPermissions } = usePermission();
  const theme = useTheme();
  const [items, setItems] = useState([]);
  const items1 = [
    {
      title: "Tableau de bord",
      path: DASHBOARD_PATH,
      icon: <Home /> /* (
        <KjosImage
          src={icon_dashboard}
          alt="Tableau de bord"
          sx={{
            width: theme.typography.fontSize,
            height: theme.typography.fontSize,
            objectFit: "cover",
          }}
        />
      ), */
    },
    {
      title: "Signalements",
      path: SIGNALEMENTS_URL, 
      icon: <SvgIcon> <BellAlertIcon /></SvgIcon>  /* (
        <KjosImage
          src={icon_signalements}
          alt="Signalements"
          sx={{
            width: theme.typography.fontSize,
            height: theme.typography.fontSize,
            objectFit: "cover",
          }}
        />
      ), */
    },

    {
      title: "Plaintes",
      path: PLAINTES_LIST_PATH,
      icon: <ErrorOutlineIcon />
    },

    {
      title: "Utilisateurs",
      path: UTILISATEURS_URL,
      icon: <SvgIcon> <PersonIcon /></SvgIcon> /* (
        <KjosImage
          src={icon_users}
          alt="Users"
          sx={{
            width: theme.typography.fontSize,
            height: theme.typography.fontSize,
            objectFit: "cover",
          }}
        />
      ), */
    },
    {
      title: "Administrateurs",
      path: ADMINS_URL,
      icon: <SupervisorAccountIcon /> /*  (
        <KjosImage
          src={icon_admins}
          alt="Admins"
          sx={{
            width: theme.typography.fontSize,
            height: theme.typography.fontSize,
            objectFit: "cover",
          }}
        />
      ), */
    },

    {
      title: "Articles",
      path: ARTICLE_LIST_PATH,
      icon: <DescriptionIcon />
    },
    {
      title: "CMS",
      path: CMS_URL,
      icon:<WebIcon />
    },
    {
      title: "Rapport",
      path: RAPPORT_PATH,
      icon:<ReportIcon />
    },
    {
      title: "PUSH",
      path: MESSAGE_PATH,
      icon:<Message />
    },
    {
      title: "Paramétrage",
      path: WORKFLOW_URL,
      icon: <SettingsIcon /> /* (
        <KjosImage
          src={icon_settings}
          alt="Paramétrage"
          sx={{
            width: theme.typography.fontSize,
            height: theme.typography.fontSize,
            objectFit: "cover",
          }}
        />
      ), */
    },

  ];
  const items2 = async () => {
    const permissions = [
      "show_dashboard",
      "read_signalement",
      "read_user",
      "read_admin",
      "admin_settings",
      "CMS_management",
      "articles_management",
      "complaints_management",
      "report_management",
      "message_management",
    ];
    const permissionResults = await Promise.all(
      permissions?.map((permission) => hasPermission(permission))
    );


    const filteredArray = items1.filter((obj, index) => {
      if(permissionResults[8000]?.authorized){
        return true
      }else{
        if (obj.title === "Tableau de bord" && permissionResults[0]?.authorized) {
          return true;
        }
        if (obj.title === "Signalements" && permissionResults[1]?.authorized) {
          return true;
        }
        if (obj.title === "Utilisateurs" && permissionResults[2]?.authorized) {
          return true;
        }
        if (obj.title === "Administrateurs" && permissionResults[3]?.authorized) {
          return true;
        }
        if (obj.title === "Paramétrage" && (permissionResults[4]?.authorized)) {
          return true;
        }
       
        if (obj.title === "CMS" && (permissionResults[5]?.authorized)) {
          return true;
        }
        if (obj.title === "Articles" && (permissionResults[6]?.authorized)) {
          return true;
        }
        if (obj.title === "Plaintes" && (permissionResults[7]?.authorized)) {
          return true;
        }
        if (obj.title === "Rapport" && (permissionResults[8]?.authorized)) {
          return true;
        }
        if (obj.title === "PUSH" && (permissionResults[9]?.authorized)) {
          return true;
        }
        return false;
      }

  
    });
    setItems(filteredArray);
  };
  useEffect(() => {
    items2();
  }, []);
  // const items = [
  //   {
  //     title: "Tableau de bord",
  //     path: "/",
  //     icon: (
  //       <KjosImage
  //         src={icon_dashboard}
  //         alt="Tableau de bord"
  //         sx={{
  //           width: theme.typography.fontSize,
  //           height: theme.typography.fontSize,
  //           objectFit: "cover",
  //         }}
  //       />
  //     ),
  //   },
  //   {
  //     title: "Signalements",
  //     path: "/signalements",
  //     icon: (
  //       <KjosImage
  //         src={icon_signalements}
  //         alt="Signalements"
  //         sx={{
  //           width: theme.typography.fontSize,
  //           height: theme.typography.fontSize,
  //           objectFit: "cover",
  //         }}
  //       />
  //     ),
  //   },
  //   {
  //     title: "Utilisateurs",
  //     path: "/utilisateurs",
  //     icon: (
  //       <KjosImage
  //         src={icon_users}
  //         alt="Users"
  //         sx={{
  //           width: theme.typography.fontSize,
  //           height: theme.typography.fontSize,
  //           objectFit: "cover",
  //         }}
  //       />
  //     ),
  //   },
  //   useRole("admin", "superadmin")
  //     ? {
  //         title: "Administrateurs",
  //         path: "/administrateurs",
  //         icon: (
  //           <KjosImage
  //             src={icon_admins}
  //             alt="Admins"
  //             sx={{
  //               width: theme.typography.fontSize,
  //               height: theme.typography.fontSize,
  //               objectFit: "cover",
  //             }}
  //           />
  //         ),
  //       }
  //     : {
  //         title: null,
  //         path: null,
  //         icon: null,
  //       },
  //   useRole("admin", "superadmin")
  //     ? {
  //         title: "Paramétrage",
  //         path: "/workflow",
  //         icon: (
  //           <KjosImage
  //             src={icon_settings}
  //             alt="Paramétrage"
  //             sx={{
  //               width: theme.typography.fontSize,
  //               height: theme.typography.fontSize,
  //               objectFit: "cover",
  //             }}
  //           />
  //         ),
  //       }
  //     : {
  //         title: null,
  //         path: null,
  //         icon: null,
  //       },
  //   // {
  //   //   title: 'Comptes',
  //   //   path: '/account',
  //   //   icon: (
  //   //     <SvgIcon fontSize="small">
  //   //       <UserIcon />
  //   //     </SvgIcon>
  //   //   )
  //   // },
  //   // {
  //   //   title: 'Paramètre',
  //   //   path: '/settings',
  //   //   icon: (
  //   //     <SvgIcon fontSize="small">
  //   //       <CogIcon />
  //   //     </SvgIcon>
  //   //   )
  //   // },
  // ];

  return items;
}

export default useNavLink;
