import { Stack } from "@mui/system";
import { useRef } from "react";
import { avatar_anonyme, delete_icon } from "config/image";

export default function KjosInputFile(
  props,
  {
    fileSizeConstraint = 10048576,
    fileSelectedConstraint = 6,
    errorLabelColor = "red",
    errorMsgFileSizeConstraint = "La taille de fichier ne doit pas dépasser 10Mo",
    errorMsgFileSelectedConstraint = "Le nombre d'image sélectionné ne doit pas dépasser 6",
  }
) {
  var imgArray = [];
  var size = 0;

  const imageFactory = (e) => {
    const file = e.target.files;
    const files = Object.keys(file);
    var divErrorFileSize = document.createElement("div");
    var divErrorFileSelected = document.createElement("div");
    const errorFileListRef = props?.errorFileListRef?.current;

    // Lorsque cette option est utilisée les images s'afficheront dans l'lement spécifié avec reference ref
    const photoContainer = props?.refImage?.current;

    files.forEach((element, i) => {
      imgArray.push(file[element]);

      const photoUrl = URL.createObjectURL(file[element]);

      photoContainer.src = photoUrl;

      document.getElementById("removePhoto").addEventListener("click", () => {
        photoContainer.src = avatar_anonyme;
        delete imgArray[i];
      });
      size = size + file[element].size;
    });

    //   Contrainte sur la taille
    if (imgArray.length > fileSelectedConstraint) {
      imgArray = [];
      divErrorFileSelected.style.color = errorLabelColor;
      divErrorFileSelected.innerHTML = errorMsgFileSelectedConstraint;
      errorFileListRef.innerHTML = "";
      errorFileListRef.appendChild(divErrorFileSelected);
    } else {
      if (size > fileSizeConstraint) {
        // flush list of item previewly saved
        size = 0;
        imgArray = [];
        divErrorFileSelected.style.color = errorLabelColor;
        divErrorFileSelected.innerHTML = errorMsgFileSelectedConstraint;
        errorFileListRef.innerHTML = "";
        errorFileListRef.appendChild(divErrorFileSelected);
        divErrorFileSize.remove();
        divErrorFileSize.style.color = errorLabelColor;

        divErrorFileSize.innerHTML = errorMsgFileSizeConstraint;
        errorFileListRef.innerHTML = "";
        errorFileListRef.appendChild(divErrorFileSize);
      }
    }
  };

  const fileFactory = (e) => {
    const file = e.target.files;
    const files = Object.keys(file);
    var divListOfFile = document.createElement("div");
    var divErrorFileSize = document.createElement("div");
    var divErrorFileSelected = document.createElement("div");
    const errorFileListRef = props?.errorFileListRef?.current;
    const fileListRef = props?.fileListRef?.current;

    fileListRef.style.display = "flex";
    fileListRef.style.flexDirection = "column";
    fileListRef.style.width = "100%";

    files.forEach((element, i) => {
      imgArray.push(file[element]);

      var listOfFile = document.createElement("div");
      listOfFile.style.display = "flex";
      listOfFile.style.flexDirection = "row";
      listOfFile.style.justifyContent = "space-between";
      listOfFile.style.width = "100%";

      var divFileName = document.createElement("div");
      var divCloseIcon = props?.closeIconRef?.current ?? document.createElement("div");

      listOfFile.id = "listOfFile" + i;

      // close icon
      const deleteIcon = props?.deleteIconRef?.current ?? document.createElement("img");
      deleteIcon.src = delete_icon;
      deleteIcon.style.width = "1.5rem";
      deleteIcon.style.height = "1.5rem";
      deleteIcon.id = "deleteIcon" + i;

      deleteIcon.addEventListener("click", () => {
        listOfFile.remove();
        delete imgArray[i];
        // var fileBuffer = new DataTransfer();
        // for (var i = 0; i < imgArray.length; i++) {
        //   fileBuffer.items.add(imgArray[i]);
        // }
        // mainImageRef.current.files = fileBuffer?.files;
        props.mainImageRef.current.value = "";
      });

      divFileName.innerHTML = "-" + file[element].name;
      divCloseIcon.append(deleteIcon);

      listOfFile.appendChild(divFileName);
      props?.closeIconRef?.current ?? listOfFile.appendChild(divCloseIcon);

      divListOfFile.appendChild(listOfFile);
      size = size + file[element].size;
    });

    //   Contrainte sur la taille
    if (imgArray.length > fileSelectedConstraint) {
      imgArray = [];
      divErrorFileSelected.style.color = errorLabelColor;
      divErrorFileSelected.innerHTML = errorMsgFileSelectedConstraint;
      errorFileListRef.innerHTML = "";
      errorFileListRef.appendChild(divErrorFileSelected);
    } else {
      if (size > fileSizeConstraint) {
        // flush list of item previewly saved
        if (imgArray.length > 0) divListOfFile.innerHTML = "";
        size = 0;

        divErrorFileSize.remove();
        divErrorFileSize.style.color = errorLabelColor;

        divErrorFileSize.innerHTML = errorMsgFileSizeConstraint;
        errorFileListRef.innerHTML = "";
        errorFileListRef.appendChild(divErrorFileSize);
      } else {
        errorFileListRef.innerHTML = "";
        fileListRef.appendChild(divListOfFile);
      }
    }
  };

  const handleOnChange = (e) => {
    props?.imageMode ? imageFactory(e) : fileFactory(e);
  };
  // const handleOnChange = (e) => {
  //   const file = e.target.files;
  //   const files = Object.keys(file);
  //   var divListOfFile = document.createElement("div");
  //   var divErrorFileSize = document.createElement("div");
  //   var divErrorFileSelected = document.createElement("div");
  //   const errorFileListRef = props?.errorFileListRef?.current;
  //   const fileListRef = props?.fileListRef?.current;

  //   // Lorsque cette option est utilisée les images s'afficheront dans l'lement spécifié avec reference ref
  //   const photoContainer = props?.refImage?.current;
  //   photoContainer.style.width = "100%";
  //   photoContainer.style.height = "100%";
  //   photoContainer.style.position = "relative";

  //   fileListRef.style.display = "flex";
  //   fileListRef.style.flexDirection = "column";
  //   fileListRef.style.width = "100%";

  //   files.forEach((element, i) => {
  //     imgArray.push(file[element]);

  //     if (props.printImage) {
  //       const photoUrl = URL.createObjectURL(file[element]);

  //       const refImage = document.createElement("img");
  //       refImage.style.width = "100%";
  //       refImage.src = photoUrl;

  //       const iconDeletePhoto = document.createElement("img");
  //       iconDeletePhoto.style.position = "absolute";
  //       iconDeletePhoto.style.top = 0;
  //       iconDeletePhoto.style.right = 0;
  //       iconDeletePhoto.style.width = "1.5rem";
  //       iconDeletePhoto.style.height = "1.5rem";
  //       iconDeletePhoto.src = delete_icon;

  //       photoContainer.appendChild(refImage);
  //       photoContainer.appendChild(iconDeletePhoto);

  //       iconDeletePhoto.addEventListener("click", () => {
  //         refImage.remove();
  //         iconDeletePhoto.remove();
  //         delete imgArray[i];
  //       });
  //     }

  //     var listOfFile = document.createElement("div");
  //     listOfFile.style.display = "flex";
  //     listOfFile.style.flexDirection = "row";
  //     listOfFile.style.justifyContent = "space-between";
  //     listOfFile.style.width = "100%";

  //     var divFileName = document.createElement("div");
  //     var divCloseIcon = props?.closeIconRef?.current ?? document.createElement("div");

  //     listOfFile.id = "listOfFile" + i;

  //     // close icon
  //     const deleteIcon = props?.deleteIconRef?.current ?? document.createElement("img");
  //     deleteIcon.src = delete_icon;
  //     deleteIcon.style.width = "1.5rem";
  //     deleteIcon.style.height = "1.5rem";
  //     deleteIcon.id = "deleteIcon" + i;

  //     deleteIcon.addEventListener("click", () => {
  //       listOfFile.remove();
  //       delete imgArray[i];
  //     });

  //     divFileName.innerHTML = "-" + file[element].name;
  //     divCloseIcon.append(deleteIcon);

  //     listOfFile.appendChild(divFileName);
  //     props?.closeIconRef?.current ?? listOfFile.appendChild(divCloseIcon);

  //     divListOfFile.appendChild(listOfFile);
  //     size = size + file[element].size;
  //   });

  //   //   Contrainte sur la taille
  //   if (imgArray.length > fileSelectedConstraint) {
  //     imgArray = [];
  //     divErrorFileSelected.style.color = errorLabelColor;
  //     divErrorFileSelected.innerHTML = errorMsgFileSelectedConstraint;
  //     errorFileListRef.innerHTML = "";
  //     errorFileListRef.appendChild(divErrorFileSelected);
  //   } else {
  //     if (size > fileSizeConstraint) {
  //       // flush list of item previewly saved
  //       if (imgArray.length > 0) divListOfFile.innerHTML = "";
  //       size = 0;

  //       divErrorFileSize.remove();
  //       divErrorFileSize.style.color = errorLabelColor;

  //       divErrorFileSize.innerHTML = errorMsgFileSizeConstraint;
  //       errorFileListRef.innerHTML = "";
  //       errorFileListRef.appendChild(divErrorFileSize);
  //     } else {
  //       errorFileListRef.innerHTML = "";
  //       fileListRef.appendChild(divListOfFile);
  //     }
  //   }
  // };
  return (
    <div style={{ width: props.width, height: props.height }}>
      <div
        style={{
          width: props.width,
          height: props.height,
          border: props.border,
          borderRadius: props.borderRadius,
          backgroundColor: props.backgroundColor,
          position: "relative",
        }}
      >
        <input
          accept={props.accept}
          type="file"
          name={props.name}
          id={props.id}
          hidden
          onChange={handleOnChange}
          multiple={props.multiple}
          ref={props?.mainImageRef}
        />
        {props?.imageMode ? (
          <Stack direction={"row"} justifyContent={"center"} spacing={2}>
            <label htmlFor={props.id}>{props.editIcon}</label>
            <label id="removePhoto">{props.deleteIcon}</label>
          </Stack>
        ) : (
          <>
            <label htmlFor={props.id}>{props.component}</label>
          </>
        )}

        <div style={{ width: "100%" }}>
          <div
            style={{
              color: props.colorLabel,
              fontSize: props.fontSizeLabel,
              width: "100%",
              height: "100%",
              position: "absolute",
              bottom: 0,
              left: 0,
              transform: "translate(0%, -50%)",
            }}
          >
            {props.label}
          </div>
        </div>
      </div>
    </div>
  );
}
