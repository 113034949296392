import { Button, SvgIcon, Typography } from "@mui/material";
import ArchiveBoxIcon from "@heroicons/react/24/solid/ArchiveBoxIcon";
import KjosDialog2 from "components/KjosDialog2";
import { Stack } from "@mui/system";
import { setOpen, setUpdate } from "store/AuthenticationReducer";
import { useDispatch, useSelector } from "react-redux";
import useSignalement from "hooks/useSignalement";
import useActionButton from "hooks/useActionButton";
import useHistorique from "hooks/useHistorique";
import useUser from "hooks/useUser";
import { useState } from "react";
import { useEffect } from "react";
import { ADMIN_SIGNALLEMENT_URL, ANONYME_HISTORIQUE_URL } from "config/RoutesUrl";
import usePermission from "hooks/usePermission";
import useHttp from "hooks/useHttp";
import { setAlert } from "store/SignalementReducer";
import { CREATE_COLOR, DELETE_COLOR, FANCY_GREEN, FANCY_ORANGE } from "config/color";

export default function ClasseSansuiteButton(props) {
  const { signalement, updateParent, signalementId } = props;
  const [dialogTitle, setDialogTitle] = useState(""); // open snackbar
  const [dialogBody, setDialogBody] = useState(""); // open snackbar
  const [dialogMessage, setDialogMessage] = useState(""); // open snackbar
  const [dialogMessageColor, setDialogMessageColor] = useState(""); // open snackbar
  const [motif, setMotif] = useState("");
  const dispatch = useDispatch();
  const { createSignalement, updateSignalement, getAdminSignalement } = useSignalement(); // get data from server
  const { setHistorique, getHistorique } = useHistorique();
  const { disableClasseSansSuiteButton } = useActionButton();
  const { getUser, getUserHabilitation } = useUser();
  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { hasPermission } = usePermission();
  const [permissionsToDo, setPermissionsToDo] = useState(false);
  const [permissionsToUndo, setPermissionsToUndo] = useState(false);
  const [callBackSignalement, setCallBackSignalement] = useState(false);
  const {getOneData} = useHttp();
  const { update } = useSelector((state) => state.authentication); // get result state

  const classifierBody = (
    <Stack direction={"row"} spacing={2} justifyContent={"center"} sx={{ width: "100%" }}>
      <Typography variant="body2">Veuillez valider pour confirmer</Typography>
    </Stack>
  );

  const handleClasseSansSuite = () => {
    setDialogBody(classifierBody);
    setDialogTitle("Classe sans suite");
    setMotif("without_continuation");

    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleUndo = async (e) => {
      const data = {
        statut: "open",
      };

      const classeSansuite = await updateSignalement(signalement?.id, data);
      setDialogMessage("Le signalement a été rappellé ");
      setDialogMessageColor("green");

      if (!classeSansuite?.error) {
        // Ajoute une historique
        const response = await setHistorique(
          "open",
          signalement?.id,
          getUser("admin")?.user?.id,
          "L'administrateur " +
            getUser("admin")?.user?.nom +
            " a rappellé le signalement : "
        );

        dispatch(setAlert({ open: true, message: "Le signalement a été rappellé", color: CREATE_COLOR }));
        setOpen(false);
      } else {
        setDialogMessage(classeSansuite?.message);
        setDialogMessageColor("red");

        dispatch(setAlert({ open: true, message: "Erreur lors du rappel. Si le problème persiste, veuillez contacter l'administrateur.", color: "error" }));
        setOpen(false);
      }
    dispatch(setUpdate());
  };

  const submitDialogCallback = async (e) => {
    if (motif === "without_continuation") {
      const data = {
        statut: "without_continuation",
      };

      const classeSansuite = await updateSignalement(signalement?.id, data);
      setDialogMessage("Le signalement a été classé ");
      setDialogMessageColor("green");

      if (!classeSansuite?.error) {
        // Ajoute une historique
        const response = await setHistorique(
          "without_continuation",
          signalement?.id,
          getUser("admin")?.user?.id,
          "L'administrateur " +
            getUser("admin")?.user?.nom +
            " a classé le signalement sans suite: "
        );

        dispatch(setAlert({ open: true, message: "Le signalement a été classé sans suite", color: DELETE_COLOR }));
        setOpen(false);
      } else {
        setDialogMessage(classeSansuite?.message);
        setDialogMessageColor("red");

        dispatch(setAlert({ open: true, message: "Une erreur s'est produite. Si le problème persiste, veuillez contacter l'administrateur.", color: "error" }));
        setOpen(false);
      }
    }
    dispatch(setUpdate());
  };

  useEffect(() => {
    disableClasseSansSuiteButton(signalementId).then((resp) => {
      setDisabled(resp);
    });

    hasPermission("class_without_continuation").then((resp) => {
      setPermissionsToDo(resp?.authorized);
    });

    hasPermission("class_without_continuation_callback").then((resp) => {
      
      setPermissionsToUndo(resp?.authorized);
    });

    getOneData(signalementId, ADMIN_SIGNALLEMENT_URL).then((resp) => {
      if(resp?.data?.statut === "without_continuation"){
        setCallBackSignalement(true);
      }else{
        setCallBackSignalement(false);
      }
    })

  }, [disableClasseSansSuiteButton, getHistorique, getOneData, hasPermission, signalementId, update]);

  return (
    <>
    
    {
      callBackSignalement ? (
        permissionsToUndo &&  (
          <Button
            startIcon={
              <SvgIcon fontSize="small">
                <ArchiveBoxIcon />
              </SvgIcon>
            }
            variant="contained"
            color="error"
            onClick={handleUndo}
            disabled={!disabled}
            sx={{ bgcolor: FANCY_GREEN, color: "white" }}
          >
            Rappeller
          </Button>
        )
      ):(
        permissionsToDo && (
          <Button
            startIcon={
              <SvgIcon fontSize="small">
                <ArchiveBoxIcon />
              </SvgIcon>
            }
            variant="contained"
            color="error"
            onClick={handleClasseSansSuite}
            disabled={disabled}
            sx={{ bgcolor: FANCY_ORANGE, color: "white" }}
            >
            Classer sans suite
          </Button>
        )
      )
    }
      

      {/* Dialogue */}
      <KjosDialog2
        title={dialogTitle}
        body={dialogBody}
        button="Valider"
        submitCallback={submitDialogCallback}
        dialogMessage={dialogMessage}
        dialogMessageColor={dialogMessageColor}
        open={open}
        handleClose={handleDialogClose}
      />
    </>
  );
}
