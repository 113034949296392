import { useState } from "react";
import useLocalSorage from "./useLocalSorage";
import { useEffect } from "react";
import { useCallback } from "react";
import { avatar_anonyme_boy } from "config/image";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "store/AuthenticationReducer";
import { Avatar, Chip } from "@mui/material";
import useHttp from "./useHttp";
import { setCount } from "store/SignalementReducer";
import getDefaultImage from "controller/getDefaultImage";
import KjosChip from "components/KjosChip";
import useStatut from "./useStatut";
import { ADMIN_USER_URL } from "config/RoutesUrl";

function useUser() {
  const [getLocalStorage, removeLocalStorage, setLocalStorage] = useLocalSorage();
  const dispatch = useDispatch();
  const { getData, postData, putData, deleteData, getOneData } = useHttp(); // get data from server
  const {color} = useStatut();
  const updateUser = useCallback(
    (name, data) => {
      // Get the existing data
      const localStorageData = getLocalStorage(name);
      var localUser = localStorageData?.user;
      // If no existing data, create an array
      // Otherwise, convert the localStorage string to an array

      //  loaclUser = loaclUser ? JSON.parse(loaclUser) : {};
      if (data !== undefined && data !== null) {
        Object.keys(data).forEach((key) => {
          // Add new data to localStorage Array
          if (key != "email") {
            localUser[key] = data[key];
          }
        });
      }

      dispatch(setUser(localUser)); //update user state
      localStorageData.user = localUser; // Save back to localStorage
      setLocalStorage(name, localStorageData);
    },
    [getLocalStorage, dispatch, setLocalStorage]
  );

  const getUser = useCallback(
    (name) => {
      const localStorageData = getLocalStorage(name);
      var localUser = localStorageData?.user; //get user data
      //If user photo null set default photo
      const photo = localUser?.photo ?? avatar_anonyme_boy;
      localStorageData && localStorageData.user && (localStorageData.user.photo = photo);
      dispatch(setUser(localStorageData?.user)); //update user state
      setLocalStorage(name, localStorageData);
      return localStorageData;
    },
    [getLocalStorage, dispatch, setLocalStorage]
  );

  const getUserToken = useCallback(
    (name) => {
      const localStorageData = getLocalStorage(name);
      var localUser = localStorageData?.user; //get user data
      //If user photo null set default photo
      const photo = localUser?.photo ?? avatar_anonyme_boy;
      localStorageData && localStorageData.user && (localStorageData.user.photo = photo);
      dispatch(setUser(localStorageData?.user)); //update user state
      setLocalStorage(name, localStorageData);
      return localStorageData?.token;
    },
    [getLocalStorage, dispatch, setLocalStorage]
  );

  const getUserHabilitation = useCallback(
    (name) => {
      const localStorageData = getLocalStorage(name);
      return localStorageData?.habiletes;
    },
    [getLocalStorage]
  );

  const fetchUser = useCallback(
    async (url, page = 1, per_page = 5, query = null) => {
      const adm = query
        ? await getData(url + "?page=" + page + "&per_page=" + per_page + "&" + query)
        : await getData(url + "?page=" + page + "&per_page=" + per_page);

      dispatch(setCount(adm?.count));
      const req = adm?.data?.map((item) => {
        return {
          id: item?.id,
          contact: item?.contact,
          habilete: item?.habilete?.libelle ? item?.habilete?.libelle : "",
          // date: item?.date,
          avatar: <Avatar src={getDefaultImage(item?.photo)} />,
          nom: item?.email ? (item?.nom === "undefined" ? "" : item?.nom) : "Anonyme",
          prenom: item?.prenom === "undefined" ? "" : item?.prenom,
          email: item?.email ? (item?.email === "undefined" ? "" : item?.email) : "Anonyme",
          adresse: item?.adresse === "undefined" ? "" : item?.adresse,
          statut: (
            <KjosChip
              label={item?.statut}
              color={color(item?.statut)} 
              size="small"
              variant="contained"
            />
          ),
        };
      });
      return req;
    },
    [dispatch, getData]
  );

  const searchUser = useCallback(
    async (url, query) => {
      const req = await getData(url + "?" + query);
      dispatch(setCount(req?.count));

      const result = req?.data?.map((item) => {
        return {
          id: item?.id,
          // date: item?.date,
          nom: item?.email ? (item?.nom === "undefined" ? "" : item?.nom) : "Anonyme",
          prenom: item?.prenom === "undefined" ? "" : item?.prenom,
          email: item?.email ? (item?.email === "undefined" ? "" : item?.email) : "Anonyme",
          adresse: item?.adresse === "undefined" ? "" : item?.adresse,
          statut: (
            <KjosChip
              label={item?.statut}
              color={color(item?.statut)}
              size="small"
              variant="contained"
            />
          ),
        };
      });
      return result;
    },
    [dispatch, getData]
  );

  const getAllAdmins = useCallback(
    async () => {
      const users = await getData(ADMIN_USER_URL);
      const usersArray = users?.data?.map((item) => {
        return {
          id: item.id,
          label: item.nom + " " + item.prenom,
        };
      })

      return usersArray;
    },[getData]
  )

  return { getUser, updateUser, getUserToken, getUserHabilitation, fetchUser, searchUser, getAllAdmins };
}

export default useUser;
