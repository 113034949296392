import { Box, Button, Chip, Container, Snackbar, Stack, SvgIcon, Typography } from "@mui/material";
import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import { Link } from "react-router-dom";
import KjosList2 from "components/KjosList2";
import KjosSnacBar from "components/KjosSnackBar";
import { useState } from "react";
import KjosDialog from "components/KjosDialog";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDeleteResult, setOpen } from "store/AuthenticationReducer";
import useHttp from "hooks/useHttp";
import KjosInput from "components/KjosInput";
import {
  ADMIN_INDEX_TRAITEMENT,
  ADMIN_SIGNALLEMENT_URL, 
  ADMIN_TYPE_SIGNALEMENT_URL,
} from "config/RoutesUrl";
import PencilIcon from "@heroicons/react/24/solid/PencilIcon";
import TrashIcon from "@heroicons/react/24/solid/TrashIcon";
import PaperAirplaneIcon from "@heroicons/react/24/solid/PaperAirplaneIcon";
import ArchiveBoxIcon from "@heroicons/react/24/solid/ArchiveBoxIcon";
import EyeIcon from "@heroicons/react/24/solid/EyeIcon";
import KjosRichText from "components/KjosRichText";
import getArrayItemsById from "controller/getArrayItemsById";
import KjosSvgIcon from "components/KjosSvgIcon";
import ArrowRightIcon from "@heroicons/react/24/solid/ArrowRightIcon";
import KjosChip from "components/KjosChip";
import useSignalement from "hooks/useSignalement";
import useUser from "hooks/useUser";
import useHistorique from "hooks/useHistorique";
import KjosSyncButton from "components/KjosSyncButton";
import { setAlert, setLoading, setPage, setRowPerpage } from "store/SignalementReducer";
import { useNavigate } from "react-router-dom";
import { SIGNALEMENT_DETAIL_URL } from "config/PathUrl";
import KjosAlert from "components/KjosAlert";
import { FANCY_BLUE, FANCY_GREEN } from "config/color";

const SignalementLayoutAdmin = () => {
  const dispatch = useDispatch(); 
  const { getUser } = useUser();
  const { updateSignalement, getAdminSignalement, querySignalement } = useSignalement(); // get data from server
  const { postData, putData, getOneData } = useHttp(); // get data from server
  const [dialogMessage, setDialogMessage] = useState(""); // open snackbar
  const [dialogMessageColor, setDialogMessageColor] = useState(""); // open snackbar
  const { setHistorique } = useHistorique();
  const router = useNavigate();
  const { postResult, getResult, deleteResult, editResult, update } = useSelector(
    (state) => state.authentication
  ); // get result state
  const [motif, setMotif] = useState("add"); // add, edit
  const { itemsSelected } = useSelector((state) => state.table); // selected items from table
  const [openSnackBar, setOpenSnackBar] = useState(false); // open snackbar
  const [confirmDelete, setConfirmDelete] = useState(false); // open snackbar
  const [dialogBody, setDialogBody] = useState(""); // open snackbar
  const [dialogTitle, setDialogTitle] = useState(""); // open snackbar
  const [signalements, setSignalements] = useState([]); // open snackbar
  const { page, rowsPerPage } = useSelector((state) => state.signalement);
  /* Table Header */
  const header = {
    // date: "Date",
    code: "Code",
    date: "Date",
    user: "Utilisateurs",
    type: "Type",
    localites: "Localités",
    workflow: "STATUT",
  };

  const handleSearch = async (e) => {
    // const req = await querySignalement(e.target.value);
    const sign = await getAdminSignalement(page + 1, rowsPerPage, "order=desc&order_by=created_at&code=" + e.target.value);
      setSignalements(sign)
  };
 
  
  const handledetail = () => {
    itemsSelected.length === 1 &&
      router(SIGNALEMENT_DETAIL_URL,{state: { id: itemsSelected[0] } });
  };

  const handleCreate = () => {
    router("/signalements/create");
  };
  /* En Button control */

  /* Snackbar */
  const handleOnclose = () => {
    setOpenSnackBar(false);
  };

  useEffect(() => {
    async function fetchData() {
      const sign = await getAdminSignalement(page + 1, rowsPerPage, "order=desc&order_by=created_at&statut=new,open");
      dispatch(setLoading(false)); // setLoading
      setSignalements(sign);
    }
    fetchData(); // get data when component is mounted
    // const interval = setInterval(fetchData(), 6000)
    return () => {
      setOpenSnackBar(false);
      dispatch(
        setAlert({
          open: false,
          message: "",
        }));
    };
  }, [dispatch, getAdminSignalement, page, rowsPerPage, update]);

  useEffect(() => {}, [itemsSelected]);
  useEffect(() => {
    return () => {
        dispatch(setPage(0));
        dispatch(setRowPerpage(5));
    };
  }, [dispatch]);
  return ( 
    <Box component={"main"} sx={{ flexGrow: 1, p: "1rem" }}>
    <KjosAlert label={alert.message} />
      <Container maxWidth={"xl"}>
        <Stack spacing={3} direction={"column"}>
          <Stack direction={{ xs: "column", lg: "row" }} justifyContent={"space-between"}>
            {/* <Typography variant="h6">Signalements non traités</Typography> */}
            <div>
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <Button
                  color="error"
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  disabled={itemsSelected.length >= 1 && true}
                  variant="contained"
                  onClick={handleCreate}
                  sx={{ bgcolor: FANCY_BLUE, color: "white" }}
                  >
                  Créer
                </Button>

                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <EyeIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  disabled={itemsSelected.length == 1 ? false : true}
                  onClick={handledetail}
                  sx={{ bgcolor: FANCY_GREEN, color: "white" }}
                >
                  Détails
                </Button>

                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PaperAirplaneIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  color="info"
                  disabled={itemsSelected.length >= 1 ? false : true}
                >
                  Historiques
                </Button>

                <KjosSyncButton />

              </Stack>
            </div>
          </Stack>

          <Stack>
            <KjosList2 data={signalements} header={header} isCheckBox={true} key={page} title="Nouveaux" onSearch={handleSearch} placeholder="Rechercher par le numéro de signalement" />
          </Stack>
        </Stack>
      </Container>

  
      {/* When we delete an item we open snackbar */}
      <KjosSnacBar
        open={openSnackBar}
        onClose={handleOnclose}
        message={
          (deleteResult?.message?.libelle && deleteResult?.message?.libelle[0]) ??
          deleteResult?.message
        }
        autoHideDuration={6000}
        severity={
          deleteResult?.message?.libelle && deleteResult?.message?.libelle[0] ? "error" : "success"
        }
      />
    </Box>
  );
};

export default SignalementLayoutAdmin;

export const Circuit = (props) => {
  const { classe, habiletes } = props;
  return (
    <Stack
      direction={"column"}
      justifyContent={"center"}
      alignContent={"center"}
      alignItems={"center"}
    >
      <Typography variant="body">{classe?.libelle}</Typography>{" "}
      <Stack direction={"row"} spacing={1} alignItems={"center"} justifyContent={"center"}>
        {classe?.habiletes?.map((hab, index) => (
          <Stack key={index} direction={"row"} alignItems={"center"} spacing={1}>
            <Stack direction={"column"}>
              {hab?.map((item, i) => (
                <KjosChip
                  key={i}
                  label={getArrayItemsById(item, habiletes)?.libelle}
                  size="small"
                  variant="outlined"
                  color="info"
                />
              ))}
            </Stack>

            {index < classe?.habiletes?.length - 1 && <span>{"->"}</span>}
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
