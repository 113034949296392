import { Button, SvgIcon, Typography } from "@mui/material";
import ArchiveBoxIcon from "@heroicons/react/24/solid/ArchiveBoxIcon";
import KjosDialog2 from "components/KjosDialog2";
import KjosSpiner from "components/KjosSpiner";
import { Stack } from "@mui/system";
import { setOpen, setUpdate } from "store/AuthenticationReducer";
import { useDispatch, useSelector } from "react-redux";
import useSignalement from "hooks/useSignalement";
import useHistorique from "hooks/useHistorique";
import useUser from "hooks/useUser";
import { useState } from "react";
import KjosRichText from "components/KjosRichText";
import ChatBubbleLeftRightIcon from "@heroicons/react/24/solid/ChatBubbleLeftRightIcon";
import { useRef } from "react";
import getCurrentDate from "controller/getCurrentDate";
import useActionButton from "hooks/useActionButton";
import { useEffect } from "react";
import useCommentaire from "hooks/useCommentaire";
import { setAlert } from "store/SignalementReducer";
import { CREATE_COLOR, FANCY_GREEN } from "config/color";

export default function CommentButton(props) {
  const { signalement, updateParent, signalementId } = props;
  const fileRef = useRef();
  const audioRef = useRef();
  const videoRef = useRef();
  const [dialogTitle, setDialogTitle] = useState(""); // open snackbar
  const [spiner, setSpiner] = useState(""); // open snackbar
  const [dialogBody, setDialogBody] = useState(""); // open snackbar
  const { disableCommentButton } = useActionButton();
  const [dialogMessage, setDialogMessage] = useState(""); // open snackbar
  const [dialogMessageColor, setDialogMessageColor] = useState(""); // open snackbar
  const [motif, setMotif] = useState("");
  const { createSignalement, updateSignalement, getAdminSignalement } = useSignalement(); // get data from server
  const { setHistorique, setHistorique2 } = useHistorique();
  const { setCommentaire } = useCommentaire();
  const { getUser } = useUser();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [disabled, setDisabled] = useState(false);
  const [comment, setComment] = useState("");
  const handleOnRichTextUpdate = (value) => {
    setComment(value);
  };
  const { update } = useSelector((state) => state.authentication); // get result state

  const commentBody = <KjosRichText onUpdate={handleOnRichTextUpdate} fileRef={fileRef} audioRef={audioRef} videoRef={videoRef} />;
  const handleComment = () => {
    setDialogMessage("");
    setDialogBody(commentBody);
    setDialogTitle("Commentaire");
    setMotif("comment");

    setOpen(true);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const submitDialogCallback = async (e) => {
    setSpiner(<KjosSpiner size="1.5rem" />);

    if (motif === "comment") {
      // if comment is empty
      if (comment.replace(/\s+/g, "") === "") {
        setSpiner("");
        setDialogMessage("Le commentaire ne peut pas être vide");
        setDialogMessageColor("red");
        return;
      }

      
      const formData = new FormData();
      formData.append("type", "comment");
      formData.append("date", getCurrentDate());
      formData.append("signalement_id", signalement?.id);
      formData.append("admin_id", getUser("admin")?.user?.id);
      formData.append("libelle", "L'administrateur " + getUser("admin")?.user?.nom + " à laissé un commentaire: " + comment); 
      formData.append("file", fileRef.current.files[0] ?? null);
      formData.append("audio", audioRef.current.files[0] ?? null);
      formData.append("video", videoRef.current.files[0] ?? null);

      const response = await setHistorique2(formData);
      const commentaire = await setCommentaire(formData);

      

      setSpiner("");

      if (!response?.error) {
        dispatch(setAlert({ open: true, message: "Le commentaire a bien été ajouté", color: CREATE_COLOR }));
        setOpen(false);

        // setDialogMessage("Le commentaire a bien été ajouté");
        // setDialogMessageColor("green");
      } else {
        // setDialogMessage("Le commentaire n'a pas pu etre ajouté " + Array.isArray(response?.message) ? Array.isArray(response?.message?.file) && response?.message?.file[0] + " " + Array.isArray(response?.message?.audio) && response?.message?.audio[0] + " " + Array.isArray(response?.message?.video) && response?.message?.video[0] : response?.message);
        // setDialogMessageColor("red");
        dispatch(setAlert({ open: true, message: "Une erreur s'est produite, veuillez vérifier vos données.", color: "error" }));
      }
    }
    dispatch(setUpdate());
  };

  useEffect(() => {
    disableCommentButton(signalementId).then((resp) => {
      setDisabled(resp);
    });
  }, [disableCommentButton, signalementId, update]);
  return (
    <>
      {/* Button */}
      <Button
        startIcon={
          <SvgIcon fontSize="small">
            <ChatBubbleLeftRightIcon />
          </SvgIcon>
        }
        variant="contained"
        color="success"
        onClick={handleComment}
        disabled={disabled}
        sx={{ bgcolor: FANCY_GREEN, color: "white" }}
        >
        Commenter
      </Button>

      {/* Dialogue */}
      <KjosDialog2 title={dialogTitle} body={dialogBody} button="Valider" submitCallback={submitDialogCallback} dialogMessage={dialogMessage} dialogMessageColor={dialogMessageColor} open={open} handleClose={handleDialogClose} spiner={spiner} /> 
    </>
  );
}
