import KjosHeader from "components/KjosHeader";
import { Box, Container, Stack, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import useHttp from "hooks/useHttp";
import { ADMIN_USER_URL } from "config/RoutesUrl";
import AdminDetail from "pages/administrateurs/AdminDetail";
import KjosAuthorized from "components/KjosAuthorized";
import AdminPasswordEdit from "pages/administrateurs/AdminPasswordEdit";
import { useLocation } from "react-router-dom";
import KjosTitle from "components/KjosTitle";
import { SupervisorAccountTwoTone } from "@mui/icons-material";
import { FANCY_BLUE } from "config/color";
const AdminEdit = () => {
  const router = useLocation();
  const [user, setUser] = useState({});
  const { getOneData } = useHttp();

  useEffect(() => {
    const id = router.state.id;
    var user = getOneData(id, ADMIN_USER_URL).then((res) => {
      
      if (!res?.error) {
        setUser(res?.data);
      }
    });
  }, [router]);
  return (
    <KjosAuthorized permission="edit_admin">
      <KjosHeader>Modifier un administrateur| SIGNAL</KjosHeader>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="lg">
          <KjosTitle icon={<SupervisorAccountTwoTone />} title="Administrateur" subtitle="Gestion de l'administrateur" iconBgColor={FANCY_BLUE} />
          <br />

          <Stack spacing={3}>
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <AdminDetail user={user} />
                </Grid>
                <Grid item xs={12}>
                  <AdminPasswordEdit user={user} />
                </Grid>
              </Grid>
            </div>
          </Stack>
        </Container>
      </Box>
    </KjosAuthorized>
  );
};

export default AdminEdit;
