import React, { useEffect, useRef, useState } from "react";
import { YMaps, Map, Placemark, ZoomControl, TrafficControl, RouteButton, RouteEditor, FullscreenControl, GeolocationControl, TypeSelector, Circle, ObjectManager } from "@pbe/react-yandex-maps";
import axios from "axios";
import { Divider, IconButton, InputBase, Paper, Stack, Typography } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import DeliveryDiningIcon from "@mui/icons-material/DeliveryDining";
import { Link, useNavigate } from "react-router-dom";
import { map_picto } from "config/image";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer, useMap } from "react-leaflet";
import { Icon, divIcon, point } from "leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import getDefaultImage from "controller/getDefaultImage";
import { SIGNALEMENT_DETAIL_URL } from "config/PathUrl";
import useHttp from "hooks/useHttp";

const KjosMap = ({ locations, zoom = 9, position = [9.6345247, -13.7441446] }) => {
  const {getData} = useHttp();
  const router = useNavigate();
  const mapRef = useRef(null);
  const [center, setCenter] = useState([9.6345247, -13.7441446]);
  const icon = new Icon({ iconUrl: map_picto, iconSize: [40, 40] });
  const inconClusterFunction = (cluster) => {
    const count = cluster.getChildCount();
    const size = count < 100 ? "small" : count < 1000 ? "medium" : "large";
    return new divIcon({
      html: `<div class="cluster-icon">${count}</div>`,
      className: `marker-cluster marker-cluster-${size}`,
      iconSize: point(40, 40, true),
    });
  };

  useEffect(() => {
    console.log('location-----------------', locations)
    if (position === null) {
      const loc = locations[0];
      const isLoc = loc && loc.latitude && loc.longitude ? true : false;
      if(isLoc){
        setCenter([loc.longitude, loc.latitude]);
      }
    }
  }, [locations]);

  return (
    // Make sure you set the height and width of the map container otherwise the map won't show
    <MapContainer center={position} zoom={zoom} ref={mapRef} style={{ height: "75vh", width: "100%" }}>
      <ChangeView center={center} zoom={zoom} />
      {/* <MapEvents /> */}
      <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      <MarkerClusterGroup chunkedLoading iconCreateFunction={inconClusterFunction}>
        {Array.isArray(locations) &&
          locations.length > 0 &&
          locations.map((location, index) => {
            return (
              <>
                {location && location.latitude && location.longitude && (
                  <Marker key={location.id} position={[location.longitude, location.latitude]} icon={icon}>
                    <Popup key={`popup-${location.id}`}>
                      <Stack direction="column" spacing={1}>
                        <Stack direction="row" spacing={1}>
                          <img src={getDefaultImage(location?.user?.photo)} style={{ width: "5rem", height: "6rem", objectFit: "contain", borderRadius: "2px" }} alt={location && location.user && location.user.nom + " " + location.user.prenom} />
                          <div style={{ paddingTop: "2rem" }}>
                            <Typography variant="body2"> {location && location.user && location.user.nom.slice(0, 10) + location && location.user && location.user.prenom.slice(0, 20)} </Typography>
                          </div>
                        </Stack>
                        <Typography variant="h6"> Code : {location.code} </Typography>
                        <Typography variant="body2"> Description : {location && location.description && location.description.slice(0, 100)} </Typography>
                        <Typography variant="body1"> Lieu des faits : {location && location.commune && location.commune.libelle} </Typography>
                        <Link onClick={() => router(SIGNALEMENT_DETAIL_URL, { state: { id: location?.id } })}>Voir plus... </Link>
                      </Stack>
                    </Popup>
                  </Marker>
                )}
              </>
            );
          })}
      </MarkerClusterGroup>
    </MapContainer>
  );
};

export default KjosMap;

function ChangeView({ center, zoom }) {
  const map = useMap();
  map.setView(center, zoom);
  return null;
}

function MapEvents() {
  const map = useMap();

  useEffect(() => {
    map.on('moveend', () => {
      const bounds = map.getBounds();
    });

    // Clean up the event handler when the component is unmounted
    return () => {
      map.off('moveend');
    };
  }, [map]);

  return null;
}
