import Error from "404";
import App from "_app";
import IsAuthUser from "components/IsAuthUser";
import { ADMINS_CREATE_URL, ADMINS_URL, ADMIN_EDIT_URL, ARTICLE_CREATE_PATH, ARTICLE_DETAIL_PATH, ARTICLE_EDIT_PATH, ARTICLE_LIST_PATH, CMS_URL, HABILETE_EDIT_URL, MY_ACCOUNT_URL, PLAINTES_DETAIL_PATH, PLAINTES_LIST_PATH, RAPPORT_LIST_PATH, RAPPORT_PATH, RAPPORT_SEND, RAPPORT_SEND_BY_FILTER, SIGNALEMENTS_CREATE_URL, SIGNALEMENTS_URL, SIGNALEMENT_DETAIL_URL, SIGNIN_PATH, SIGNUP_PATH, USER_CREATE_URL, USER_EDIT_URL, UTILISATEURS_URL, WORKFLOW_URL, RESET_PASSWORD_PATH, SIGNALEMENT_MAP_URL, MESSAGE_PATH, MESSAGE_CREATE_PATH, MESSAGE_DETAIL_PATH } from "config/PathUrl";
import Layout from "layouts/layout";
import Dashboard from "pages";
import CmsEdit from "pages/cms/edit";
import Account from "pages/administrateurs/account";
import Administrateurs from "pages/administrateurs/administrateurs";
import AddAdmin from "pages/administrateurs/create";
import AdminEdit from "pages/administrateurs/edit";
import Login from "pages/auth/login";
import Register from "pages/auth/register";
import HabiletesEdit from "pages/settings/HabiletesEdit";
import Workflow from "pages/settings/workflow";
import AddSignalement from "pages/signalements/create";
import SignalementDetail from "pages/signalements/detail";
import Signalement from "pages/signalements/signalements";
import ListeDesPlaintes from "pages/plaintes/list";
import PlainteDetail from "pages/plaintes/detail";
import AddUser from "pages/user/ajouter";
import EditUser from "pages/user/edit";
import User from "pages/user/utilisateurs";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import 'style/style.css';
import TestTest from "test/Test";
import ListArticle from "pages/articles/list";
import CreateArticle from "pages/articles/create";
import EditArticle from "pages/articles/edit";
import DetailArticle from "pages/articles/detail";
import Rapport from "pages/rapport/Rapport";
import RapportList from "pages/rapport/RapportList";
import RapportSend from "pages/rapport/RapportSend";
import RapportSendByFilter from "pages/rapport/RapportSendByFilter";
import ResetPassword from "pages/auth/resetPassword";
import SignalementsMap from "pages/signalements/SignalementsMap";
import MessagesLayout from "pages/messages/MessagesLayout";
import CreateMessage from "pages/messages/ajouter";
import MessageDetail from "pages/messages/MessageDetail";

const router = createBrowserRouter([
  {
    exact: true,
    path: "/",
    element: (
      <IsAuthUser>
        <Layout>
        <Dashboard />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: SIGNIN_PATH,
    element: (
      <IsAuthUser>
        <Login />
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: RESET_PASSWORD_PATH,
    element: (
      <IsAuthUser>
        <ResetPassword />
      </IsAuthUser>
    ),
    error: <Error />,
  },


  {
    path: SIGNUP_PATH,
    element: (
      <IsAuthUser>
        <Register />
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: USER_EDIT_URL,
    element: (
      <IsAuthUser>
        <Layout>
        <EditUser />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: MESSAGE_PATH,
    element: (
      <IsAuthUser>
        <Layout>
        <MessagesLayout />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: MESSAGE_CREATE_PATH,
    element: (
      <IsAuthUser>
        <Layout>
        <CreateMessage />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },
  {
    path: MESSAGE_DETAIL_PATH,
    element: (
      <IsAuthUser>
        <Layout>
        <MessageDetail />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: USER_CREATE_URL,
    element: (
      <IsAuthUser>
        <Layout>
        <AddUser />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: ADMIN_EDIT_URL,
    element: (
      <IsAuthUser>
        <Layout>
        <AdminEdit />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: HABILETE_EDIT_URL,
    element: (
      <IsAuthUser>
        <Layout>
        <HabiletesEdit />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: SIGNALEMENT_DETAIL_URL,
    element: (
      <IsAuthUser>
        <Layout>
        <SignalementDetail />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: SIGNALEMENTS_CREATE_URL,
    element: (
      <IsAuthUser>
        <Layout>
        <AddSignalement />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: SIGNALEMENTS_URL,
    element: (
      <IsAuthUser>
        <Layout>
        <Signalement />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: UTILISATEURS_URL,
    element: (
      <IsAuthUser>
        <Layout>
        <User />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  }, 

  {
    path: ADMINS_URL,
    element: (
        <IsAuthUser>
        <Layout>
        <Administrateurs />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: ADMINS_CREATE_URL,
    element: (
      <IsAuthUser>
        <Layout>
        <AddAdmin />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: WORKFLOW_URL,
    element: (
      <IsAuthUser>
        <Layout>
          {" "}
          <Workflow />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: MY_ACCOUNT_URL,
    element: (
      <IsAuthUser>
        <Layout>
          {" "}
          <Account />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: CMS_URL,
    element: (
      <IsAuthUser>
        <Layout>
          {" "}
          <CmsEdit />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: PLAINTES_LIST_PATH,
    element: ( 
      <IsAuthUser>
        <Layout>
          {" "}
          <ListeDesPlaintes />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: PLAINTES_DETAIL_PATH,
    element: (
      <IsAuthUser>
        <Layout>
          {" "}
          <PlainteDetail />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: ARTICLE_LIST_PATH,
    element: (
      <IsAuthUser>
        <Layout>
          {" "}
          <ListArticle />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },


  {
    path: ARTICLE_CREATE_PATH,
    element: (
      <IsAuthUser>
        <Layout>
          {" "}
          <CreateArticle />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },


  {
    path: ARTICLE_EDIT_PATH,
    element: (
      <IsAuthUser>
        <Layout>
          {" "}
          <EditArticle />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: ARTICLE_DETAIL_PATH,
    element: (
      <IsAuthUser>
        <Layout>
          {" "}
          <DetailArticle />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: RAPPORT_PATH,
    element: (
      <IsAuthUser>
        <Layout>
          {" "}
          <Rapport />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },
  {
    path: RAPPORT_LIST_PATH,
    element: (
      <IsAuthUser>
        <Layout>
          {" "}
          <RapportList />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },
  {
    path: RAPPORT_SEND,
    element: (
      <IsAuthUser>
        <Layout>
          {" "}
          <RapportSend />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },
  {
    path: RAPPORT_SEND_BY_FILTER,
    element: (
      <IsAuthUser>
        <Layout>
          {" "}
          <RapportSendByFilter />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: SIGNALEMENT_MAP_URL,
    element: (
      <IsAuthUser>
        <Layout>
          {" "}
          <SignalementsMap />
        </Layout>
      </IsAuthUser>
    ),
    error: <Error />,
  },

  {
    path: "/test",
    element: <TestTest /> ,
    error: <Error />,
  },


]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <App>
    {/* <React.StrictMode> */}
    <RouterProvider router={router} />
    {/* </React.StrictMode> */}
  </App>
);
