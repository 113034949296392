import React from "react";
import "./style.css";

export default function  TestTest(){
  return (
    <div className="verification-securit">
      <div className="div">
        <div className="text-wrapper">app.anssi.com</div>
        <p className="p">Vérification que la connexion au site est sécurisée</p>
        <p className="text-wrapper-2">app.anssi.com doit vérifier la sécurité de votre connexion avant de continuer</p>
        <div className="overlap-group">
          {/* <div className="rectangle" /> */}
          <input type="checkbox" className="test-checkbox" />
          <div className="v-rifiez-que-vous">
            Vérifiez que vous
            {/* <br /> */}
            êtes humain
          </div>
        </div>
        <img className="ellipse" alt="Ellipse" src="/test/ellipse-11.png" />
      </div>
    </div>
  );
};
