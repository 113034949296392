import KjosHeader from "components/KjosHeader";
import { Box, Container, Stack } from "@mui/material";
import UtilisateursLayout from "pages/user/UtilisateursLayout";
import KjosAuthorized from "components/KjosAuthorized";
import { FANCY_BLUE } from "config/color";
import BellAlertIcon from "@heroicons/react/24/solid/BellAlertIcon";
import UserIcon from "@heroicons/react/24/solid/UserIcon";
import KjosTitle from "components/KjosTitle";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";

const now = new Date();

const User = () => {
  return (
    <KjosAuthorized permission="read_user">
      <KjosHeader>Utilisateurs | SIGNAL</KjosHeader>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Stack sx={{ px: 5 }}>
            <KjosTitle icon={<UsersIcon />} title="Utilisateurs" subtitle="Liste des utilisateurs" iconBgColor={FANCY_BLUE} />
          </Stack>

          <br />
          <Stack spacing={3}>
            <UtilisateursLayout />
          </Stack>
        </Container>
      </Box>
    </KjosAuthorized>
  );
};

export default User;
