import { Box, Container, Grid, IconButton, Paper, Stack, SvgIcon, Typography } from "@mui/material";
import KjosAuthorized from "components/KjosAuthorized";
import KjosHeader from "components/KjosHeader";
import KjosTitle from "components/KjosTitle";
import { FANCY_BLUE } from "config/color";
import FlagIcon from "@mui/icons-material/Flag";
import { Link } from "react-router-dom";
import { RAPPORT_LIST_PATH } from "config/PathUrl";
import { Group } from "@mui/icons-material";
import { CheckCircle, Error, Warning } from "@mui/icons-material";
import { People } from "@mui/icons-material";
import ReportIcon from "@mui/icons-material/Report";
import FeedbackIcon from "@mui/icons-material/Feedback";
import { useState } from "react";
import { ArrowBack } from "@mui/icons-material";

export default function Rapport() {
  const [type, setType] = useState(null);
  return (
    <KjosAuthorized permission="report_management">
      <KjosHeader>rapport | SIGNAL</KjosHeader>
      <Box component={"main"} sx={{ flexGrow: 1, py: 3 }}>
        <Container maxWidth={"xl"}>
          <Stack sx={{ p: 5 }} direction={"row"} alignItems={"center"} spacing={3}>
            {type && (
              <IconButton onClick={() => setType(null)}>
                <ArrowBack />
              </IconButton>
            )}
            <KjosTitle icon={<ReportIcon />} title="Rapport" subtitle="Générer un rapport" iconBgColor={FANCY_BLUE} />
          </Stack>
          <br />
          {!type && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={4}>
                <Link style={{ textDecoration: "none" }} onClick={() => setType("Signalement")}>
                  <Paper sx={{ p: 3 }} elevation={3}>
                    <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={3}>
                      <ReportIcon />
                      <Link style={{ textDecoration: "none", color: FANCY_BLUE }} onClick={() => setType("Signalement")}>
                        {" "}
                        <Typography variant="h6">Rapport par Signalements</Typography>{" "}
                      </Link>
                    </Stack>
                  </Paper>
                </Link>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Link style={{ textDecoration: "none" }} onClick={() => setType("Plainte")}>
                  <Paper sx={{ p: 3 }} elevation={3}>
                    <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={3}>
                      <FeedbackIcon />
                      <Link style={{ textDecoration: "none", color: FANCY_BLUE }} onClick={() => setType("Plainte")}>
                        {" "}
                        <Typography variant="h6">Rapport par Plaintes</Typography>{" "}
                      </Link>
                    </Stack>
                  </Paper>
                </Link>
              </Grid>
            </Grid>
          )}
          <br />
          {type && (
            <Grid container spacing={3}>
              {/* <Grid item xs={12} md={6} lg={4}>
                <Link style={{ textDecoration: "none" }} to={RAPPORT_LIST_PATH + "?filter=groupe_by_localites&type=" + type}>
                  <Paper sx={{ p: 3 }} elevation={3}>
                    <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={3}>
                      <FlagIcon code="fr" />
                      <Link style={{ textDecoration: "none", color: FANCY_BLUE }} to={RAPPORT_LIST_PATH + "?filter=groupe_by_localites"}>
                        {" "}
                        <Typography variant="h6">Rapport selon la localité</Typography>{" "}
                      </Link>
                    </Stack>
                  </Paper>
                </Link>
              </Grid> */}

            {type === "Signalement" &&  <Grid item xs={12} md={6} lg={4}>
                <Link style={{ textDecoration: "none" }} to={RAPPORT_LIST_PATH + "?filter=groupe_by_type_signalement&type=" + type}>
                  <Paper sx={{ p: 3 }} elevation={3}>
                    <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={3}>
                      <Group />
                      <Link style={{ textDecoration: "none", color: FANCY_BLUE }} to={RAPPORT_LIST_PATH + "?filter=groupe_by_type_signalement"}>
                        {" "}
                        <Typography variant="h6">Rapport selon le type</Typography>{" "}
                      </Link>
                    </Stack>
                  </Paper>
                </Link>
              </Grid>}

              <Grid item xs={12} md={6} lg={4}>
                <Link style={{ textDecoration: "none" }} to={RAPPORT_LIST_PATH + "?filter=groupe_by_status&type=" + type}>
                  <Paper sx={{ p: 3 }} elevation={3}>
                    <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={3}>
                      <CheckCircle /> {/* Success status */}
                      <Link style={{ textDecoration: "none", color: FANCY_BLUE }} to={RAPPORT_LIST_PATH + "?filter=groupe_by_status"}>
                        {" "}
                        <Typography variant="h6">Rapport selon le status</Typography>{" "}
                      </Link>
                    </Stack>
                  </Paper>
                </Link>
              </Grid>

              <Grid item xs={12} md={6} lg={4}>
                <Link style={{ textDecoration: "none" }} to={RAPPORT_LIST_PATH + "?filter=groupe_by_user&type=" + type}>
                  <Paper sx={{ p: 3 }} elevation={3}>
                    <Stack direction={"column"} justifyContent={"center"} alignItems={"center"} spacing={3}>
                      <People />
                      <Link style={{ textDecoration: "none", color: FANCY_BLUE }} to={RAPPORT_LIST_PATH + "?filter=groupe_by_user"}>
                        {" "}
                        <Typography variant="h6">Rapport selon l'utilisateur</Typography>{" "}
                      </Link>
                    </Stack>
                  </Paper>
                </Link>
              </Grid>
            </Grid>
          )}
        </Container>
      </Box>
    </KjosAuthorized>
  );
}
